import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createExcelExport,
  createExport,
  createRawDataExport,
  createSPSSExport,
  deleteSavedExport,
  generateRawExport,
  generateSpssExport,
  saveExportBanners,
} from 'services/backend/exports';
import { fetchQuestionsForSurvey } from 'services/backend/questions';
import { fetchSurvey } from 'services/backend/surveys';
import {
  formDataToApiExcelExportData,
  formDataToApiExportData,
  getInitialExportFormData,
} from 'util/exports';
import { generateQuestionnaireDocx } from 'util/docx';
import { getQuestionOptions } from 'util/formOptions';
import { partitionQuestionsForDemographics } from 'util/questions';
import { ReactQueryFunctionCallbacks } from 'types/internal';
import { showErrorMessage } from 'util/notifications';
import { questionQueries } from './questions';

export function useCreateExcelExport(
  opts?: ReactQueryFunctionCallbacks<typeof createExcelExport>,
) {
  return useMutation({ ...opts, mutationFn: createExcelExport });
}

export function useCreateExport(
  opts?: ReactQueryFunctionCallbacks<typeof createExport>,
) {
  return useMutation({ ...opts, mutationFn: createExport });
}

export function useCreateRawDataExport(
  opts?: ReactQueryFunctionCallbacks<typeof createRawDataExport>,
) {
  return useMutation({ ...opts, mutationFn: createRawDataExport });
}

export function useCreateSPSSExport(
  opts?: ReactQueryFunctionCallbacks<typeof createSPSSExport>,
) {
  return useMutation({ ...opts, mutationFn: createSPSSExport });
}

export function useDeleteSavedExport(
  opts?: ReactQueryFunctionCallbacks<typeof deleteSavedExport>,
) {
  return useMutation({ ...opts, mutationFn: deleteSavedExport });
}

export function useDownloadQuestionnaire({ surveyId }: { surveyId: number }) {
  return useMutation({
    mutationFn: async ({
      includeInactiveQuestions,
    }: {
      includeInactiveQuestions: boolean;
    }) => {
      const [survey, questions] = await Promise.all([
        fetchSurvey({ surveyId }),
        fetchQuestionsForSurvey({ surveyId }),
      ]);

      generateQuestionnaireDocx({
        isMaster: includeInactiveQuestions,
        passedQuestions: questions,
        survey,
      });
    },
    onError: (err: Error) => {
      showErrorMessage(
        `Failed to generate questionnaire download. Error: ${err.message}`,
      );
    },
  });
}

export function useGenerateRawExport(
  opts?: ReactQueryFunctionCallbacks<typeof generateRawExport>,
) {
  return useMutation({ ...opts, mutationFn: generateRawExport });
}

export function useGenerateSpssExport(
  opts?: ReactQueryFunctionCallbacks<typeof generateSpssExport>,
) {
  return useMutation({ ...opts, mutationFn: generateSpssExport });
}

export function useGenerateSummary(opts: {
  onError(err: Error): void;
  onSuccess(): void;
  surveyId: number;
}) {
  const { refetch: fetchSurveyQuestions } = useQuery(
    questionQueries.segmentationQuestions({ surveyId: opts.surveyId }),
  );

  const { mutateAsync: createExcelExport } = useCreateExcelExport();
  const { mutateAsync: createExport } = useCreateExport();

  return useMutation({
    ...opts,
    mutationFn: async ({
      generateNewExports,
      surveyId,
      waveIds,
    }: {
      generateNewExports: boolean;
      surveyId: number;
      waveIds: number[];
    }) => {
      const { data } = await fetchSurveyQuestions();
      const questions = getQuestionOptions({ questions: data ?? [] });
      const [demographicQuestions, surveyQuestions] =
        partitionQuestionsForDemographics(questions);

      const exportFormData = getInitialExportFormData({ questions });
      // Not entirely sure why version 1 of the code set "stackQuestionLabels" to false but it
      // did and currently don't have the bandwidth to dig in to this so keeping the functionality
      // here the same as v1.
      exportFormData.features.stackQuestionLabels = false;
      exportFormData.useReconcile = false;
      exportFormData.waveIds = waveIds;

      if (generateNewExports) {
        createExcelExport({
          data: formDataToApiExcelExportData({ formData: exportFormData }),
          surveyId,
        });
      } else {
        createExport({
          data: formDataToApiExportData({
            formData: exportFormData,
            demographicQuestions,
            surveyQuestions,
            waveIds,
          }),
          surveyId,
        });
      }
    },
  });
}

export function useSaveExportBanners(
  opts?: ReactQueryFunctionCallbacks<typeof saveExportBanners>,
) {
  return useMutation({ ...opts, mutationFn: saveExportBanners });
}
