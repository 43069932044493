import { useState } from 'react';
import { Formik, FormikErrors, useField, useFormikContext } from 'formik';

import {
  ConstraintWithRange,
  ExportBanner,
  ExportFormData,
  getEmptyConstraintWithConcepts,
  getEmptyConstraintWithLabel,
  getEmptyConstraintWithOptions,
  getEmptyConstraintWithRange,
  getEmptyConstraintWithSegments,
  getEmptyConstraintWithWaves,
  getEmptyExportFilter,
  HardcodedWavesQuestion,
  isSurveyVariableFilterQuestion,
  isWaveFilterQuestion,
  MODIFIER_OPTION_EITHER,
} from '../../util/exports';
import {
  getOptionOption,
  getConceptOption,
  getSurveyWaveOption,
} from '../../util/formOptions';
import { isIdeaPresenterQuestion } from '../../util/questions';
import {
  Question,
  QuestionLabel,
  QUESTION_TYPE,
  SurveyVariable,
} from '../../types/domainModels';
import { QuestionGroup } from '../../types/internal';
import { ReactSelectValue } from '../../types/forms';

import AddButton from '../common/forms/AddButton';
import Button from '../common/forms/Button';
import FormCheckbox from '../common/forms/FormCheckbox';
import FormFieldLabel from '../common/forms/FormFieldLabel';
import FormInput from '../common/forms/FormInput';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Sidebar, { SidebarBody, SidebarHeader } from '../common/Sidebar';

interface QuickAddFormData {
  applyToExisting: boolean;
  constraintsConfig: {
    matrixOptions: ReactSelectValue<QuestionLabel>[];
    range: ConstraintWithRange['range'];
  };
  quickAddQuestion: ReactSelectValue<Question> | null;
}

interface QuickAddFormDataValidated {
  applyToExisting: boolean;
  constraintsConfig: {
    matrixOptions: ReactSelectValue<QuestionLabel>[];
    range: ConstraintWithRange['range'];
  };
  quickAddQuestion: ReactSelectValue<Question>;
}

function getNewBannerPoints({
  constraintsConfig,
  existingBannerPoint,
  question,
}: {
  constraintsConfig: QuickAddFormDataValidated['constraintsConfig'];
  existingBannerPoint?: ExportBanner;
  question:
    | ReactSelectValue<Question>
    | ReactSelectValue<SurveyVariable>
    | ReactSelectValue<HardcodedWavesQuestion>;
}): ExportBanner[] {
  const newBannerPoints: ExportBanner[] = [];

  const existingFilters = existingBannerPoint?.filters ?? [];
  const existingName = existingBannerPoint
    ? `${existingBannerPoint.name} - `
    : '';

  if (isWaveFilterQuestion(question.value)) {
    question.value.waves.forEach((wave) => {
      const filter = getEmptyExportFilter();
      filter.question = question;

      const constraint = getEmptyConstraintWithWaves();
      const waveOption = getSurveyWaveOption(wave.value);
      constraint.waves = [waveOption];

      filter.constraints = [constraint];

      newBannerPoints.push({
        filters: [...existingFilters, filter],
        name: `${existingName}${waveOption.label}`,
      });
    });
  } else if (isSurveyVariableFilterQuestion(question.value)) {
    question.value.segments.forEach((segment) => {
      const filter = getEmptyExportFilter();
      filter.question = question;

      const constraint = getEmptyConstraintWithSegments();
      const segmentOption = { label: segment.title, value: segment };
      constraint.segments = [segmentOption];

      filter.constraints = [constraint];

      newBannerPoints.push({
        filters: [...existingFilters, filter],
        name: `${existingName}${segmentOption.label}`,
      });
    });
  } else if (isIdeaPresenterQuestion(question.value)) {
    (question.value.concepts ?? []).forEach((concept) => {
      const filter = getEmptyExportFilter();

      const conceptOption = getConceptOption({ concept });
      const constraint = getEmptyConstraintWithConcepts();
      constraint.concepts = [conceptOption];

      filter.constraints = [constraint];
      filter.question = question;

      newBannerPoints.push({
        filters: [...existingFilters, filter],
        name: `${existingName}${conceptOption.label}`,
      });
    });
  } else {
    question.value.options.forEach((option) => {
      const filter = getEmptyExportFilter();
      const optionOption = getOptionOption({ option });
      filter.question = question;

      if (
        isSurveyVariableFilterQuestion(question.value) ||
        isWaveFilterQuestion(question.value)
      ) {
        return;
      } else if (
        question.value.questionTypeId === QUESTION_TYPE.RANKING ||
        question.value.questionTypeId === QUESTION_TYPE.SCALE
      ) {
        const constraint = getEmptyConstraintWithRange();
        constraint.option = optionOption;
        constraint.range = constraintsConfig.range;

        filter.constraints = [constraint];
      } else if (question.value.questionTypeId === QUESTION_TYPE.MATRIX) {
        const constraint = getEmptyConstraintWithLabel();
        constraint.label = optionOption;
        constraint.options = constraintsConfig.matrixOptions;

        filter.constraints = [constraint];
        // We change the modifier for Matrix questions because the user can select multiple
        // Matrix options to apply for each new banner point (and "equal to" only supports
        // one Matrix option).
        filter.modifier = MODIFIER_OPTION_EITHER;
      } else {
        const constraint = getEmptyConstraintWithOptions();
        constraint.options = [optionOption];

        filter.constraints = [constraint];
      }

      newBannerPoints.push({
        filters: [...existingFilters, filter],
        name: `${existingName}${optionOption.label}`,
      });
    });
  }

  return newBannerPoints;
}

function validateQuickAddData(
  formData: QuickAddFormData,
): FormikErrors<QuickAddFormData> {
  const errors: FormikErrors<QuickAddFormData> = {};

  if (!formData.quickAddQuestion) {
    errors.quickAddQuestion = 'Please select a question.';
  }

  return errors;
}

const CrosstabQuickAdd = ({
  existingBannerPoints,
  onAddBannerPoints,
  questions,
}: {
  existingBannerPoints: ExportFormData['banners'];
  onAddBannerPoints(
    banners: ExportFormData['banners'],
    opts: { shouldReplace: boolean },
  ): void;
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}): JSX.Element => {
  const [isQuickAddOpen, setIsQuickAddOpen] = useState(false);

  return (
    <>
      <AddButton
        label="Quick Add Banner Points"
        onClick={() => {
          setIsQuickAddOpen(true);
        }}
      />
      <Sidebar isOpen={isQuickAddOpen}>
        <SidebarBody>
          <SidebarHeader
            onClickClose={() => {
              setIsQuickAddOpen(false);
            }}
          >
            Quick Add Banner Points
          </SidebarHeader>
          <div className="w-quick-add-sidebar text-sm">
            <div className="mb-4 space-y-4">
              <p>
                Select a question below to quickly add banner points for each of
                its options.
              </p>
              <p>
                For example, if you select the demographic question "What is
                your gender?", a new banner point will be created for each of
                the three options: "Male", "Female" and "Other" (using an "equal
                to" modifier and using the name of the option as the name of the
                banner point).
              </p>
            </div>
            <Formik<QuickAddFormData>
              initialValues={{
                applyToExisting: false,
                constraintsConfig: {
                  matrixOptions: [],
                  range: { end: '', start: '' },
                },
                quickAddQuestion: null,
              }}
              onSubmit={(formData) => {
                const validatedFormData = formData as QuickAddFormDataValidated;
                let newBannerPoints: ExportBanner[] = [];

                if (formData.applyToExisting) {
                  existingBannerPoints.forEach((bannerPoint) => {
                    newBannerPoints = [
                      ...newBannerPoints,
                      ...getNewBannerPoints({
                        constraintsConfig: formData.constraintsConfig,
                        existingBannerPoint: bannerPoint,
                        question: validatedFormData.quickAddQuestion,
                      }),
                    ];
                  });
                } else {
                  newBannerPoints = getNewBannerPoints({
                    constraintsConfig: formData.constraintsConfig,
                    question: validatedFormData.quickAddQuestion,
                  });
                }

                onAddBannerPoints(newBannerPoints, {
                  shouldReplace: formData.applyToExisting,
                });
                setIsQuickAddOpen(false);
              }}
              validate={validateQuickAddData}
              validateOnChange={false}
            >
              <QuickAddBannerPointsForm questions={questions} />
            </Formik>
          </div>
        </SidebarBody>
      </Sidebar>
    </>
  );
};

export default CrosstabQuickAdd;

const QuickAddBannerPointsForm = ({
  questions,
}: {
  questions: QuestionGroup<
    Question | SurveyVariable | HardcodedWavesQuestion
  >[];
}): JSX.Element => {
  const { submitForm } = useFormikContext();

  // We don't currently support Number questions (like "What is your age?") because the expected
  // behavior is undefined. We can iterate on this if needed once we get feedback.
  const filteredQuestions = questions.map((questionGroup) => {
    return {
      ...questionGroup,
      options: questionGroup.options.filter((question) => {
        return isSurveyVariableFilterQuestion(question.value) ||
          isWaveFilterQuestion(question.value)
          ? true
          : question.value.questionTypeId !== QUESTION_TYPE.NUMBER;
      }),
    };
  });

  return (
    <div>
      <div className="space-y-4">
        <FormSearchSelectInput
          label="Question"
          name="quickAddQuestion"
          options={filteredQuestions}
        />
        <QuickAddQuestionConstraintConfig />
        <FormCheckbox
          checkboxLabel="Apply to Existing Banner Points"
          name="applyToExisting"
          tooltip={
            <div className="space-y-2">
              <p>
                This feature allows you to apply the new banner points from the
                selected question to the existing banner points in your crosstab
                analysis.
              </p>
              <p>
                For example, if you already have banner points for concept seen
                (e.g. Concept 1, Concept 2), you could choose to apply a
                demographic question to the existing concept banner points. This
                will result in new banner points:
                <ul className="pl-8 list-disc">
                  <li>Concept 1 - Male</li>
                  <li>Concept 1 - Female</li>
                  <li>Concept 1 - Other</li>
                  <li>Concept 2 - Male</li>
                  <li>Concept 2 - Female</li>
                  <li>Concept 2 - Other</li>
                </ul>
              </p>
            </div>
          }
        />
      </div>
      <div className="w-40 mt-8">
        <Button onClick={submitForm} size="md" type="button">
          Apply
        </Button>
      </div>
    </div>
  );
};

const QuickAddQuestionConstraintConfig = (): JSX.Element | null => {
  const [{ value: quickAddQuestion }] =
    useField<QuickAddFormData['quickAddQuestion']>('quickAddQuestion');

  if (!quickAddQuestion) {
    return null;
  }

  if (
    quickAddQuestion.value.questionTypeId === QUESTION_TYPE.RANKING ||
    quickAddQuestion.value.questionTypeId === QUESTION_TYPE.SCALE
  ) {
    const rangePhrase =
      quickAddQuestion.value.questionTypeId === QUESTION_TYPE.RANKING
        ? 'rank value'
        : 'value';

    return (
      <div>
        <FormFieldLabel
          label={`with a ${rangePhrase} between`}
          tooltip={`Optionally specify a default ${rangePhrase} to apply to each new banner point.`}
        />
        <div className="flex mt-1 space-x-2">
          <div className="w-16">
            <FormInput
              name="constraintsConfig.range.start"
              size="md"
              type="number"
            />
          </div>
          <span className="mt-2 text-xs">and</span>
          <div className="w-16">
            <FormInput
              name="constraintsConfig.range.end"
              size="md"
              type="number"
            />
          </div>
        </div>
      </div>
    );
  } else if (quickAddQuestion.value.questionTypeId === QUESTION_TYPE.MATRIX) {
    // All Matrix options (or "labels" on the back-end) should be the same for each
    // label (or "options" on the back-end).
    const availableOptions = quickAddQuestion.value.options[0]?.labels ?? [];

    return (
      <div>
        <FormFieldLabel
          label="with options"
          tooltip="Optionally specify the options to use for each new matrix label banner point."
        />
        <div className="mt-1">
          <FormSearchSelectInput
            isMulti={true}
            name="constraintsConfig.matrixOptions"
            options={availableOptions.map((label) => {
              return {
                label: label.optionLabel,
                value: label,
              };
            })}
          />
        </div>
      </div>
    );
  }

  return null;
};
