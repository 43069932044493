import { orderBy } from 'lodash-es';

import {
  DisplayLogicLogicalModifier,
  Question,
  QuestionConcept,
  QuestionOption,
  QuestionQuotaLogicalModifiers,
  QuestionWithResults,
  QUESTION_FEATURE,
  QUESTION_TYPE,
  SurveyVariableQuotaType,
  SurveyVariable,
  SurveyWave,
} from '../types/domainModels';
import { getConceptTitle, isDemographicQuestion } from './questions';
import { getOptionTitle, getOptionTitleIndexFromSort } from './options';
import { getSurveyWaveTitle } from './surveyWaves';
import { OptionType } from 'types/internal';
import { ReactSelectValue } from '../types/forms';

export const CARRY_FORWARD_MATRIX_ANY_OPTION: ReactSelectValue<string> = {
  label: 'Any Option',
  value: '',
};

export const DISPLAY_LOGIC_MODIFIER_OPTIONS: ReactSelectValue<DisplayLogicLogicalModifier>[] =
  [
    {
      label: 'equal to',
      value: 'is',
    },
    {
      label: 'either',
      value: 'should',
    },
    {
      label: 'not',
      value: 'isnt',
    },
  ];

export const QUESTION_QUOTA_LOGICAL_MODIFIER_OPTIONS: ReactSelectValue<QuestionQuotaLogicalModifiers>[] =
  [
    {
      label: 'At most',
      value: 'at_most',
    },
    {
      label: 'At least',
      value: 'at_least',
    },
    { label: 'All', value: 'all' },
    {
      label: 'None, or no',
      value: 'none',
    },
  ];

export const QUESTION_TYPE_OPTIONS = [
  {
    label: 'Multiple Choice',
    value: QUESTION_TYPE.MULTIPLE_CHOICE,
  },
  {
    label: 'Open Ended',
    value: QUESTION_TYPE.OPEN_ENDED,
  },
  {
    label: 'Scale',
    value: QUESTION_TYPE.SCALE,
  },
  {
    label: 'Ranking',
    value: QUESTION_TYPE.RANKING,
  },
  {
    label: 'Matrix',
    value: QUESTION_TYPE.MATRIX,
  },
  {
    label: 'Gabor-Granger',
    value: QUESTION_TYPE.GABOR_GRANGER,
  },
  {
    label: 'Idea Presenter',
    value: QUESTION_TYPE.IDEA_PRESENTER,
  },
];

export const SURVEY_VARIABLE_QUOTA_TYPE_OPTIONS: ReactSelectValue<SurveyVariableQuotaType>[] =
  [
    {
      label: 'At most',
      value: 'at_most',
    },
    { label: 'All', value: 'all' },
    {
      label: 'None, or no',
      value: 'none',
    },
  ];

export function getActiveQuestionOptions<
  T extends Question | QuestionWithResults,
>(questionOptions: ReactSelectValue<T>[]) {
  return questionOptions.filter((q) => q.value.isActive);
}

export function getCarryForwardTypeOptions(
  selectedQuestion?: Question,
): ReactSelectValue<QUESTION_FEATURE>[] {
  const labelWord =
    selectedQuestion?.questionTypeId === QUESTION_TYPE.MATRIX
      ? 'Labels'
      : 'Options';

  return [
    {
      label: `${labelWord} displayed`,
      value: QUESTION_FEATURE.CARRY_FORWARD_DISPLAYED,
    },
    {
      label: `${labelWord} selected`,
      value: QUESTION_FEATURE.CARRY_FORWARD_SELECTED,
    },
    {
      label: `${labelWord} not selected`,
      value: QUESTION_FEATURE.CARRY_FORWARD_NOT_SELECTED,
    },
  ];
}

/**
 * Returns a single option for a select input from an API question concept.
 */
export function getConceptOption({
  concept,
  index,
}: {
  concept: QuestionConcept;
  index?: number;
}): ReactSelectValue<QuestionConcept> {
  return {
    label: getConceptTitle({ concept, index }),
    value: concept,
  };
}

/**
 * Returns concepts for a select dropdown from a set of question concepts.
 */
export function getConceptOptions({
  concepts,
  filterFn,
}: {
  concepts: QuestionConcept[];
  filterFn?: (concept: QuestionConcept) => boolean;
}): ReactSelectValue<QuestionConcept>[] {
  const resolvedFilterFn = filterFn ?? (() => true);

  return concepts.filter(resolvedFilterFn).map((concept, index) => {
    return getConceptOption({ concept, index });
  });
}

/**
 * Returns a single option for a select input from an API question option.
 */
export function getOptionOption({
  index,
  option,
}: {
  index?: number;
  option: QuestionOption;
}): ReactSelectValue<QuestionOption> {
  return {
    label: getOptionTitle({ index, option }),
    value: option,
  };
}

/**
 * Returns options for a select dropdown from a set of question options.
 */
export function getOptionOptions({
  filterFn,
  options,
}: {
  filterFn?: (option: QuestionOption) => boolean;
  options: QuestionOption[] | [];
}): ReactSelectValue<QuestionOption>[] {
  const resolvedFilterFn = filterFn ?? (() => true);

  return options
    .filter(resolvedFilterFn)
    .map((option, index) => {
      return getOptionOption({
        index: option.sort ? getOptionTitleIndexFromSort(option.sort) : index,
        option,
      });
    })
    .filter((o) => o.value.isActive);
}

export function getOptionTypes({
  isAdmin,
  questionType,
}: {
  isAdmin: boolean;
  questionType: QUESTION_TYPE | undefined;
}): ReactSelectValue<OptionType>[] {
  if (questionType === QUESTION_TYPE.OPEN_ENDED) {
    const optionTypes: ReactSelectValue<OptionType>[] = [
      {
        label: 'Text',
        value: 'text',
      },
      {
        label: 'Number',
        value: 'number',
      },
      {
        label: 'Email',
        value: 'email',
      },
      {
        label: 'Zip code',
        value: 'zip',
      },
    ];

    if (isAdmin) {
      optionTypes.push({
        label: 'Audio / Video',
        value: 'audio-video',
      });
    }

    return orderBy(optionTypes, 'label');
  }

  return [
    {
      label: 'Image',
      value: 'image',
    },
    {
      label: 'Text',
      value: 'text',
    },
  ];
}

export function getQuestionOption<T extends Question | QuestionWithResults>({
  question,
}: {
  question: T;
}): ReactSelectValue<T> {
  // We apply an "D" to demographic questions to differentiate them from custom user questions.
  const sortPrefix = isDemographicQuestion(question) ? 'D' : '';
  const questionPrefix = question.sort ? `${sortPrefix}${question.sort}) ` : '';

  return {
    label: `${questionPrefix}${question.title}`,
    value: question,
  };
}

export function getWave(
  variable: SurveyVariable,
): ReactSelectValue<SurveyVariable> {
  return {
    label: variable.title,
    value: variable,
  };
}

export function getQuestionOptions<T extends Question | QuestionWithResults>({
  filterFn,
  includeInactiveQuestions = false,
  questions,
}: {
  filterFn?: (question: T) => boolean;
  includeInactiveQuestions?: boolean;
  questions: T[];
}): ReactSelectValue<T>[] {
  const resolvedFilterFn = filterFn ?? (() => true);
  const questionOptions = questions.filter(resolvedFilterFn).map((question) => {
    return getQuestionOption({ question });
  });

  return includeInactiveQuestions
    ? questionOptions
    : getActiveQuestionOptions(questionOptions);
}

export function getSurveyWaveOption(wave: SurveyWave) {
  return {
    label: getSurveyWaveTitle(wave),
    value: wave,
  };
}
