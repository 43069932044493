import { ReactNode } from 'react';

import Icon from './Icon';

const Alert = ({
  children,
  supportingText,
}: {
  children: string | string[];
  supportingText?: ReactNode;
}) => {
  return (
    <div className="relative p-4 flex gap-2 items-start text-error-d-700 rounded-xl border border-error-d-300 bg-error-d-25 shadow-sm">
      <div className="absolute -translate-y-1/4">
        <IconWithRings />
      </div>

      <div className="text-sm space-y-1 pl-12">
        <p className="font-semibold">{children}</p>

        {supportingText}
      </div>
    </div>
  );
};

export default Alert;

const IconWithRings = () => {
  return (
    <div className="relative flex items-center justify-center w-9 h-9 text-error-d-600">
      {/* Outer ring */}
      <div className="absolute inset-0 w-9 h-9 border border-error-d-600 opacity-10 rounded-full" />
      {/* Inner ring */}
      <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 w-7 h-7 border border-error-d-600 opacity-30 rounded-full" />

      <div className="w-5 h-5">
        <Icon id="alert-circle" />
      </div>
    </div>
  );
};
