import { clsx } from 'clsx';
import { ComponentProps, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { formatDollars } from '../../util/currency';
import { getTestLink } from 'util/surveys';
import { Survey } from '../../types/domainModels';
import { SurveyFlowStep, SurveyFlowSteps } from '../../types/internal';
import { useDownloadQuestionnaire } from 'hooks/backend/exports';

import Badge from 'components/common/Badge';
import Button from '../common/forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import Dropdown, {
  DropdownButton,
  DropdownItem,
} from 'components/common/Dropdown';
import Icon from 'components/common/Icon';

const SurveyEditHeader = ({
  actionButton,
  currentQuestionID,
  onClickStep,
  survey,
}: {
  actionButton?: ReactNode;
  currentQuestionID?: number;
  onClickStep?(step: SurveyFlowStep): void;
  survey: Survey;
}): JSX.Element => {
  return (
    <header className="flex h-full items-center px-6 bg-white xl:flex-wrap space-x-6 xl:space-x-0">
      <h1 className="w-survey-page-sidebar text-forest font-medium truncate shrink-0 xl:grow xl:shrink">
        {survey.title || 'New Survey'}
      </h1>

      <div className="h-full flex space-x-6 items-center xl:order-1 xl:w-full xl:h-16">
        <nav className="h-full">
          <ul className="flex h-full space-x-6">
            <li>
              <HeaderLink
                onClick={() => {
                  onClickStep?.(SurveyFlowSteps.overview);
                }}
                to={`/campaign/edit/${survey.id}/overview`}
              >
                Overview
              </HeaderLink>
            </li>
            <li>
              <HeaderLink
                onClick={() => {
                  onClickStep?.(SurveyFlowSteps.audience);
                }}
                to={`/campaign/edit/${survey.id}/audience`}
              >
                Audience
              </HeaderLink>
            </li>
            <li>
              <HeaderLink
                onClick={() => {
                  onClickStep?.(SurveyFlowSteps.questions);
                }}
                to={`/campaign/edit/${survey.id}/questions`}
              >
                Questions
              </HeaderLink>
            </li>
            <li>
              <HeaderLink
                onClick={() => {
                  onClickStep?.(SurveyFlowSteps.review);
                }}
                to={`/campaign/edit/${survey.id}/review`}
              >
                Review
              </HeaderLink>
            </li>
          </ul>
        </nav>
        {!survey.isBringYourOwnAudience && survey.estimatedBalance && (
          <Badge color="primary" size="sm">
            {formatDollars(parseInt(survey.estimatedBalance, 10))}
          </Badge>
        )}
      </div>

      <div className="flex space-x-3 grow justify-end xl:h-16 items-center">
        <PreviewButton questionId={currentQuestionID} survey={survey} />
        <DownloadButton survey={survey} />
        {actionButton}
      </div>
    </header>
  );
};

export default SurveyEditHeader;

const HeaderLink = (props: ComponentProps<typeof NavLink>) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        clsx(
          'flex border-primary-d-700 items-center h-full cursor-pointer text-sm font-semibold',
          {
            'border-b-2 text-primary-d-700 -mb-2': isActive,
            'text-gray-d-500': !isActive,
          },
        )
      }
    />
  );
};

const PreviewButton = ({
  questionId,
  survey,
}: {
  questionId?: number;
  survey: Survey;
}) => {
  return (
    <Dropdown
      button={
        <DropdownButton as="div">
          <Button
            hierarchy="secondary-gray"
            icon={<Icon id="eye" />}
            iconPlacement="leading"
            size="sm"
          >
            Preview
          </Button>
        </DropdownButton>
      }
    >
      {questionId && (
        <>
          <DropdownItem
            as="a"
            href={getTestLink({
              startingQuestionID: questionId,
              survey,
            })}
            target="_blank"
          >
            Preview Current Question
          </DropdownItem>
        </>
      )}
      <DropdownItem as="a" href={getTestLink({ survey })} target="_blank">
        Preview Entire Survey
      </DropdownItem>
    </Dropdown>
  );
};

const DownloadButton = ({ survey }: { survey: Survey }) => {
  const {
    isPending: isDownloadingQuestionnaire,
    mutate: downloadQuestionnaire,
  } = useDownloadQuestionnaire({ surveyId: survey.id });

  return (
    <Dropdown
      button={
        <DropdownButton as="div">
          <ButtonLoading
            hierarchy="secondary-gray"
            icon={<Icon id="download" />}
            iconPlacement="leading"
            isLoading={isDownloadingQuestionnaire}
            size="sm"
            type="button"
          >
            Download
          </ButtonLoading>
        </DropdownButton>
      }
    >
      <DropdownItem
        as="button"
        onClick={() => {
          downloadQuestionnaire({ includeInactiveQuestions: false });
        }}
        type="button"
      >
        Questionnaire - Active
      </DropdownItem>
      <DropdownItem
        as="button"
        onClick={() => {
          downloadQuestionnaire({ includeInactiveQuestions: true });
        }}
        type="button"
      >
        Questionnaire - All
      </DropdownItem>
    </Dropdown>
  );
};
