import { useField } from 'formik';

import { clampNumberField } from '../../../util/forms';
import { QuestionFormData } from '../../../types/forms';

import { Question } from '../../../types/domainModels';

import FormCheckbox from '../../common/forms/FormCheckbox';
import FormInput from '../../common/forms/FormInput';

type MonadicBlockFeature = QuestionFormData['features']['monadicBlock'];

const UseMonadicBlock = ({
  disabled = false,
  question,
  questionsLength,
  sequencesUpperBound,
}: {
  disabled?: boolean;
  question: Question | undefined;
  questionsLength: number;
  sequencesUpperBound: number;
}): JSX.Element => {
  const startFieldName = 'features.monadicBlock.start';
  const endFieldName = 'features.monadicBlock.end';
  const sequencesFieldName = 'features.monadicBlock.sequences';
  const [{ value: monadicBlock }] = useField<boolean>(
    'features.monadicBlock.enabled',
  );

  const [startValue, ,] =
    useField<MonadicBlockFeature['start']>(startFieldName);
  const [, , endHelpers] = useField<MonadicBlockFeature['end'] | ''>(
    endFieldName,
  );
  const [, , sequencesHelpers] = useField<MonadicBlockFeature['end'] | ''>(
    sequencesFieldName,
  );

  return (
    <div className="flex items-center">
      <FormCheckbox
        checkboxLabel="Monadic Block"
        disabled={disabled || !question}
        name="features.monadicBlock.enabled"
        tooltip=""
      />
      {monadicBlock && question && (
        <div className="flex items-center ml-4 space-x-4">
          <div className="flex items-center">
            <span className="text-sm">Start:</span>
            <div className="w-12 ml-2">
              <FormInput
                disabled={true}
                name={startFieldName}
                size="md"
                type="number"
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-sm">End:</span>
            <div className="w-12 ml-2">
              <FormInput
                disabled={!startValue}
                name={endFieldName}
                onChange={(event) => {
                  clampNumberField({
                    formHelpers: endHelpers,
                    lowerBound: question.sort,
                    upperBound: questionsLength,
                    value: event.target.value,
                  });
                }}
                size="md"
                type="number"
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="text-sm">Sequences:</span>
            <div className="w-12 ml-2">
              <FormInput
                name={sequencesFieldName}
                onChange={(event) => {
                  clampNumberField({
                    formHelpers: sequencesHelpers,
                    lowerBound: 1,
                    upperBound: sequencesUpperBound,
                    value: event.target.value,
                  });
                }}
                size="md"
                type="number"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UseMonadicBlock;
