import { Form, Formik, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import { ReactNode, useEffect, useState } from 'react';

import {
  apiDataToFormData,
  BRING_YOUR_OWN_AUDIENCE_OPTIONS,
  DemographicFormData,
  DemographicFormDataValidated,
  validateDemographicFormData,
} from '../../util/demographics';
import { getNestedErrorMessages } from 'util/forms';
import { Question, Survey } from '../../types/domainModels';
import { showErrorMessage } from '../../util/notifications';
import { SurveyFlowStep } from '../../types/internal';
import { useSaveAudience } from 'hooks/backend/audience';
import { useSubmitValidation } from '../../hooks/forms';

import {
  BuildYourAudienceCard,
  DefineAudienceCard,
} from 'components/common/Audience';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import clockPreviewImg from 'assets/img/clock-preview.png';
import FixedHeaderAndCollapsedSidebar from '../layout/FixedHeaderAndCollapsedSidebar';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormSearchSelectInput from '../common/forms/FormSearchSelectInput';
import Icon from 'components/common/Icon';
import IndexCard from '../common/IndexCard';
import Modal from 'components/common/Modal';
import { Sidebar } from '../layout/DefaultLayout';
import SkeletonSurveyCard from './SkeletonSurveyCard';
import SliderToggleFormik from 'components/common/SliderToggleFormik';
import SurveyEditHeader from './SurveyEditHeader';
import { SurveyWaveTitleEditPage } from './SurveyWaveTitle';
import SurveyWithSidebar from '../layout/SurveyWithSidebar';
import TabGroup, {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabWithAlert,
} from 'components/common/Tabs';
import UnsavedChangesModal from 'components/common/UnsavedChangesModal';

const AudienceStep = ({
  demographicQuestions,
  isLoadingSurvey,
  isShowingUnsavedChanges,
  onClickStep,
  onAudienceSaved,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onStepCompleted,
  sidebar,
  survey,
}: {
  demographicQuestions: Question[];
  isLoadingSurvey: boolean;
  isShowingUnsavedChanges: boolean;
  onAudienceSaved(): void;
  onClickStep(step: SurveyFlowStep): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onStepCompleted(): void;
  sidebar: ReactNode;
  survey: Survey | undefined;
}): JSX.Element => {
  if (isLoadingSurvey) {
    return (
      <FixedHeaderAndCollapsedSidebar
        header={
          survey ? (
            <SurveyEditHeader onClickStep={onClickStep} survey={survey} />
          ) : null
        }
        sidebar={<Sidebar isCollapsed />}
      >
        <SurveyWithSidebar sidebar={sidebar}>
          <SkeletonSurveyCard />
        </SurveyWithSidebar>
      </FixedHeaderAndCollapsedSidebar>
    );
  }

  return survey ? (
    <AudienceStepLoaded
      demographicQuestions={demographicQuestions}
      isShowingUnsavedChanges={isShowingUnsavedChanges}
      onAudienceSaved={onAudienceSaved}
      onClickStep={onClickStep}
      onDirtyChanged={onDirtyChanged}
      onDiscardChanges={onDiscardChanges}
      onDismissUnsavedChanges={onDismissUnsavedChanges}
      onHasError={onHasError}
      onStepCompleted={onStepCompleted}
      sidebar={sidebar}
      survey={survey}
    />
  ) : (
    <p className="text-red">Failed to load the survey.</p>
  );
};

export default AudienceStep;

const AudienceStepLoaded = ({
  demographicQuestions,
  isShowingUnsavedChanges,
  onClickStep,
  onAudienceSaved,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  onStepCompleted,
  sidebar,
  survey,
  useInboundCensusOptions = true,
}: {
  demographicQuestions: Question[];
  isShowingUnsavedChanges: boolean;
  onAudienceSaved(): void;
  onClickStep?(step: SurveyFlowStep): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  onStepCompleted(): void;
  sidebar?: ReactNode;
  survey: Survey;
  useInboundCensusOptions?: boolean;
}): JSX.Element => {
  const initialValues = apiDataToFormData({ demographicQuestions, survey });

  const { isPending: isSavingAudience, mutate: saveAudience } = useSaveAudience(
    {
      initialValues,
      onError: (err: Error) => {
        onHasError();
        showErrorMessage(
          `There was an error saving your audience. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        onAudienceSaved();
      },
      survey,
    },
  );

  return (
    <Formik<DemographicFormData>
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(formData) => {
        if (isEqual(initialValues, formData)) {
          onStepCompleted();
        } else {
          saveAudience(formData as DemographicFormDataValidated);
        }
      }}
      validate={validateDemographicFormData}
      validateOnBlur={false}
      validateOnChange={false}
    >
      <Form className="h-full">
        <AudienceForm
          demographicQuestions={demographicQuestions}
          isSavingAudience={isSavingAudience}
          isShowingUnsavedChanges={isShowingUnsavedChanges}
          onClickStep={onClickStep}
          onDirtyChanged={onDirtyChanged}
          onDiscardChanges={onDiscardChanges}
          onDismissUnsavedChanges={onDismissUnsavedChanges}
          onHasError={onHasError}
          sidebar={sidebar}
          survey={survey}
          useInboundCensusOptions={useInboundCensusOptions}
        />
      </Form>
    </Formik>
  );
};

const AudienceForm = ({
  demographicQuestions,
  isSavingAudience,
  isShowingUnsavedChanges,
  onClickStep,
  onDirtyChanged,
  onDiscardChanges,
  onDismissUnsavedChanges,
  onHasError,
  sidebar,
  survey,
  useInboundCensusOptions,
}: {
  demographicQuestions: Question[];
  isSavingAudience: boolean;
  isShowingUnsavedChanges: boolean;
  onClickStep?(step: SurveyFlowStep): void;
  onDirtyChanged(isDirty: boolean): void;
  onDiscardChanges(): void;
  onDismissUnsavedChanges(): void;
  onHasError(): void;
  sidebar: ReactNode;
  survey: Survey;
  useInboundCensusOptions: boolean;
}): JSX.Element => {
  const { dirty } = useFormikContext();

  const { errors, onClickSubmit, validateAndSubmit } =
    useSubmitValidation<DemographicFormData>({
      isSaving: isSavingAudience,
      onHasError,
    });

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  return (
    <FixedHeaderAndCollapsedSidebar
      header={
        survey ? (
          <SurveyEditHeader
            actionButton={
              <ButtonLoading
                hierarchy="primary"
                isLoading={isSavingAudience}
                onClick={onClickSubmit}
                size="sm"
                // This can't currently be a submit button since we handle the form submission
                // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
                type="button"
              >
                {survey && dirty ? 'Save Audience' : 'Next Step'}
              </ButtonLoading>
            }
            onClickStep={onClickStep}
            survey={survey}
          />
        ) : null
      }
      sidebar={<Sidebar isCollapsed />}
    >
      <SurveyWithSidebar sidebar={sidebar}>
        {errors ? (
          <div className="mb-8">
            <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
          </div>
        ) : null}

        <SurveyWaveTitleEditPage survey={survey} />

        {isShowingUnsavedChanges && (
          <UnsavedChangesModal
            isSaving={isSavingAudience}
            onClickDiscardChanges={onDiscardChanges}
            onClickSaveChanges={validateAndSubmit}
            onCloseModal={onDismissUnsavedChanges}
          />
        )}

        <TabGroup>
          <TabList size="sm">
            <TabWithAlert hasAlert={!!errors?.audienceProfile}>
              Audience Profile
            </TabWithAlert>
            {survey.useNewApp && <Tab>Respondent Quality</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel>
              <AudienceProfile
                demographicQuestions={demographicQuestions}
                useInboundCensusOptions={useInboundCensusOptions}
              />
            </TabPanel>
            {survey.useNewApp && (
              <TabPanel>
                <QualityChecksCard />
              </TabPanel>
            )}
          </TabPanels>
        </TabGroup>
      </SurveyWithSidebar>
    </FixedHeaderAndCollapsedSidebar>
  );
};

const AudienceProfile = ({
  demographicQuestions,
  useInboundCensusOptions,
}: {
  demographicQuestions: Question[];
  useInboundCensusOptions: boolean;
}) => {
  return (
    <IndexCard>
      <div className="p-6 space-y-4">
        <div className="space-y-2">
          <h2 className="text-gray-d-800 font-medium">Your Audience</h2>
          <p className="text-gray-d-700">
            Use our integrated audience that reaches millions of potential
            respondents, or send the survey to your own audience.
          </p>
        </div>
        <FormSearchSelectInput
          name="audienceProfile.isBringYourOwnAudience"
          options={BRING_YOUR_OWN_AUDIENCE_OPTIONS}
        />
      </div>
      <DefineAudienceCard useInboundCensusOptions={useInboundCensusOptions} />
      <BuildYourAudienceCard demographicQuestions={demographicQuestions} />
    </IndexCard>
  );
};

const QualityToggle = ({
  subtitle,
  title,
  toggle,
}: {
  subtitle: string;
  title: string;
  toggle: ReactNode;
}) => {
  return (
    <div className="space-y-2">
      <div className="flex items-center justify-between gap-4">
        <span className="text-gray-d-800 font-medium">{title}</span>
        {toggle}
      </div>
      <p className="text-gray-d-700">{subtitle}</p>
    </div>
  );
};

const QualityChecksCard = () => {
  const [showClockPreview, setShowClockPreview] = useState(false);

  return (
    <IndexCard>
      <div className="p-6 grid grid-cols-2 gap-6">
        <div>
          <span className="text-gray-d-800 font-medium">Tactics</span>
          <p className="text-gray-d-700">
            Activate or de-activate tactics to detect and disqualify low-quality
            respondents. When activated, these methods will immediately
            disqualify respondents in your survey when they show behavior or
            signals indicative of low-quality respondents.
          </p>
        </div>
        <div className="space-y-3 divide-y divide-gray-d-200">
          <QualityToggle
            subtitle="Assesses respondent for common bot and spam tactics, such as IP masking."
            title="Bad Respondent Detection"
            toggle={
              <SliderToggleFormik name="respondentQuality.qualityChecks.bad_respondent" />
            }
          />
          <div className="pt-3">
            <QualityToggle
              subtitle="Assesses whether the respondent has already taken the survey."
              title="Duplicate Detection"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.duplicate" />
              }
            />
          </div>
          <div className="pt-3">
            <QualityToggle
              subtitle="Adds a question prior to the survey to detect low-quality respondents."
              title="Pre-survey Screening Questions"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.presurvey_questionnaire" />
              }
            />

            <button
              className="mt-1 flex items-center gap-2 text-primary-d-600 hover:underline"
              onClick={() => {
                setShowClockPreview(true);
              }}
              type="button"
            >
              View Screening Question
              <div className="w-4 h-4">
                <Icon id="link-external-02" />
              </div>
            </button>
          </div>
          <div className="pt-3">
            <QualityToggle
              subtitle="Detects whether respondents are pasting text into open-end or free-form text fields."
              title="Copy/Paste Detection"
              toggle={
                <SliderToggleFormik name="respondentQuality.qualityChecks.copy_paste" />
              }
            />
          </div>
        </div>
      </div>

      {showClockPreview && (
        <Modal
          onCloseModal={() => {
            setShowClockPreview(false);
          }}
          position="center"
        >
          <img className="w-full h-full" src={clockPreviewImg} />
        </Modal>
      )}
    </IndexCard>
  );
};
