import { map } from 'lodash-es';

import {
  getOtherQuestionReferencesForOption,
  getVariableReferencesForOption,
} from '../../util/questions';
import { Question, SurveyVariable } from '../../types/domainModels';

import Button from '../common/forms/Button';
import Modal, { ModalHeader } from '../common/Modal';

const PreventDeleteOptionModal = ({
  onCloseModal,
  optionId,
  questions,
  type,
  variables,
}: {
  onCloseModal(): void;
  optionId: number;
  questions: Question[];
  type: 'concepts' | 'labels' | 'options';
  variables: SurveyVariable[];
}): JSX.Element => {
  const questionReferences = getOtherQuestionReferencesForOption({
    optionId,
    questions,
  });
  const variableReferences = getVariableReferencesForOption({
    optionId,
    variables,
  });

  let title = 'Option';
  if (type === 'concepts') {
    title = 'Concept';
  } else if (type === 'labels') {
    title = 'Label';
  }

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>Remove {title}</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
    >
      <div className="text-sm">
        <p>
          This {title.toLowerCase()} cannot be deleted or disabled because it is
          used in configuration for other questions. Please adjust the following
          questions before continuing.
        </p>
        <div className="mt-4 space-y-4">
          {questionReferences.length > 0 && (
            <h2 className="mb-1 font-semibold">QUESTIONS</h2>
          )}
          {questionReferences.map(({ title, references }) => {
            return (
              <div key={title}>
                <h2 className="mb-1 font-semibold">{title}</h2>
                <ul className="list-disc pl-8">
                  {map(references, (reference, feature) => {
                    if (typeof reference === 'boolean') {
                      if (!reference) {
                        return null;
                      }

                      if (feature === 'carryForward') {
                        return <li key={feature}>Carry Forward</li>;
                      } else if (feature === 'displayLogic') {
                        return <li key={feature}>Display Logic</li>;
                      } else if (feature === 'piping') {
                        return <li key={feature}>Answer Piping</li>;
                      }
                    } else if (
                      feature === 'displayLogicOptions' &&
                      reference &&
                      reference.length > 0
                    ) {
                      return (
                        <li key={feature}>
                          Display Logic for Options
                          <ul className="list-circle pl-8">
                            {reference.map((option) => {
                              return <li key={option}>{option}</li>;
                            })}
                          </ul>
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </div>
            );
          })}
          {variableReferences.length > 0 && (
            <h2 className="mb-1 font-semibold">HIDDEN VARIABLES</h2>
          )}
          {variableReferences.map(({ title, references }) => {
            return (
              <div key={title}>
                <h2 className="mb-1 font-semibold">{title}</h2>
                <ul className="list-disc pl-8">
                  {map(references, (reference, feature) => {
                    console.log(typeof reference);
                    if (reference.length > 0) {
                      return (
                        <li key={feature}>
                          Variable Segment
                          <ul className="list-circle pl-8">
                            {reference.map((segment) => {
                              return <li key={segment}>{segment}</li>;
                            })}
                          </ul>
                        </li>
                      );
                    } else if (typeof reference === 'boolean') {
                      if (!reference) {
                        return null;
                      }

                      return <li key={feature}>Survey Variable Segments</li>;
                    }

                    return null;
                  })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className="flex justify-end mt-8">
          <Button
            hierarchy="primary"
            onClick={onCloseModal}
            size="md"
            type="button"
          >
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PreventDeleteOptionModal;
