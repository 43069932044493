import { useState } from 'react';

import AddButton from '../common/forms/AddButton';
import Button from '../common/forms/Button';
import Checkbox from '../common/forms/Checkbox';
import Popover from '../common/Popover';
import Textarea from '../common/forms/Textarea';
import { capitalize } from 'lodash-es';

interface OnAddOptions {
  (options: string[], opts: { shouldReplace: boolean }): void;
}

function parsePastedInput(input: string): string {
  return input
    .split('\n')
    .map((line) => {
      // We want to remove anything like:
      //    "   A)   option"
      //    "1) option 2"
      //    "1. first option"
      // This is usually indicative of copying-pasting from a different document which
      // had these number bullet point indicators (Note: \u2022 is the unicode code for a bullet point).
      return line.replace(/^\s*([\w]{1,2}[).]|\u2022)\s*/gi, '');
    })
    .join('\n');
}

const PasteOptionsPopover = ({
  onAddOptions,
  type = 'option',
}: {
  onAddOptions: OnAddOptions;
  type?: 'label' | 'option';
}): JSX.Element => {
  const [isPasteOptionsOpen, setIsPasteOptionsOpen] = useState(false);

  return (
    <Popover
      isOpen={isPasteOptionsOpen}
      name={`paste-${type}s`}
      setIsOpen={setIsPasteOptionsOpen}
      trigger={(triggerProps) => (
        <div {...triggerProps}>
          <AddButton label={`Paste ${capitalize(type)}s`} />
        </div>
      )}
    >
      <PasteOptions
        onAddOptions={(...args) => {
          setIsPasteOptionsOpen(false);

          onAddOptions(...args);
        }}
        type={type}
      />
    </Popover>
  );
};

export default PasteOptionsPopover;

const PasteOptions = ({
  onAddOptions,
  type,
}: {
  onAddOptions: OnAddOptions;
  type: 'label' | 'option';
}): JSX.Element => {
  const [optionsToAdd, setOptionsToAdd] = useState('');
  const [shouldReplace, setShouldReplace] = useState(false);

  return (
    <div className="p-4 space-y-2">
      <div className="w-64 h-32 mb-4">
        <Textarea
          onChange={(event) => {
            setOptionsToAdd(event.currentTarget.value);
          }}
          onPaste={(event) => {
            // Prevent onChange from getting called when we paste into this field.
            event.stopPropagation();
            event.preventDefault();

            setOptionsToAdd(
              parsePastedInput(event.clipboardData.getData('Text')),
            );
          }}
          placeholder={`Paste ${type}s here (each ${type} on a new line)`}
          rows={4}
          size="lg"
          value={optionsToAdd}
        />
      </div>
      <Checkbox
        checked={shouldReplace}
        label={`Replace current ${type}s`}
        name={`replace-${type}s`}
        onChange={(event) => {
          setShouldReplace(event.currentTarget.checked);
        }}
      />
      <div className="flex pt-2">
        <Button
          grow
          hierarchy="primary"
          onClick={() => {
            const trimmedOptionsToAdd = optionsToAdd.trim();
            if (trimmedOptionsToAdd) {
              onAddOptions(
                trimmedOptionsToAdd.split('\n').map((option) => {
                  return option.trim();
                }),
                { shouldReplace },
              );

              setOptionsToAdd('');
            }
          }}
          size="md"
          type="button"
        >
          Add
        </Button>
      </div>
    </div>
  );
};
