export interface Audience {
  audienceSlices: {
    audienceSliceCategories: {
      audienceSliceCategoryAttributes: QuestionAudienceSliceCategoryAttribute[];
      conceptId: number | null;
      logicalModifier: AudienceLogicalModifier;
      percentage: string;
      question: Question;
      questionId: number;
    }[];
  }[];
  id: number;
  title: string;
}

export type AudienceLogicalModifier = 'is' | 'isnt' | 'should' | 'shouldnt';

export enum SpecificationType {
  average = 'average',
  bottomBox = 'bottom_x_box',
  topBox = 'top_x_box',
}

interface QuestionSpecification {
  questionId: number;
  surveyBannerId: number;
  type: SpecificationType;
  value?: number;
}

export interface Banner {
  bottomBox: { questionId: number; value: number }[];
  confidenceLevel: number | null;
  excludeQuestionIds: number[];
  exposedToQuestionIds: number[];
  id: number;
  includeInactiveQuestions: boolean;
  questionSpecifications?: QuestionSpecification[];
  rebaseIds: number[];
  showAbsolutes: boolean;
  showAllQuestionsOnOneTab: boolean;
  showPercents: boolean;
  stackComplexQuestions: boolean;
  surveyBannerSlices: {
    base: boolean;
    surveyBannerSliceCategories: {
      concept: { id: number; description: string } | null;
      question: { id: number } | null;
      surveyBannerSliceCategoryAttributes: {
        logicalModifier: ExportFilterModifier;
        surveyBannerSliceCategoryAttributeOptions: BannerSliceOption[];
      }[];
      variableId: number | null;
      useWaves: boolean;
    }[];
    title: string;
  }[];
  surveyId: number;
  title: string;
  topBox: { questionId: number; value: number }[];
  useMonadicBreakdown: boolean;

  // below are elements that will be phased out as the backend handling expands
  bottomBoxIds?: number[];
  bottomBoxValue?: number;
  topBoxIds?: number[];
  topBoxValue?: number;
}

export interface BannerCompact {
  confidenceLevel: number | null;
  excludeQuestionIds: number[];
  id: number;
  includeInactiveQuestions: boolean;
  rebaseIds: number[];
  showAbsolutes: boolean;
  showAllQuestionsOnOneTab: boolean;
  showPercents: boolean;
  stackComplexQuestions: boolean;
  surveyId: number;
  title: string;
  useMonadicBreakdown: boolean;
}

export interface BannerSliceOption {
  conceptId: number;
  enumStringValue: string;
  highOption: number;
  lowOption: number;
  optionId: number;
  segmentId: number | null;
  waveId: number | null;
}

export interface Comment {
  comment: string;
  createdAt: string;
  creator: {
    firstName: string;
    image: DataUrl | null;
    lastName: string;
  };
  creatorId: number;
  id: number;
  parentId: number | null;
  questionId?: number;
  resolved: boolean;
  surveyId?: number;
  updatedAt: string;
}

export interface DashboardSurvey {
  createdAt: string;
  endAt: string | null;
  id: number;
  incidenceType: { name: string };
  launchAt: string;
  numberOfCompletes: number;
  organizationId: number;
  participants: number;
  project: Tag | null;
  projectId: number | null;
  status: { name: SurveyStatusName };
  surveyLength: { name: string };
  title: string;
  user: {
    email: string;
    firstName: string;
    id: number;
    image: DataUrl | null;
    lastName: string;
  };
  userId: number;
  wave: SurveyWave;
  waves: SurveyWave[];
}

export interface SurveyWave {
  completes: number;
  description: string;
  endedAt: string;
  id: number;
  startedAt: string;
  surveyId: number;
  target: number;
  title: string;
  waveValue: number;
}

export interface DataUrl {
  asset_id: string;
  bytes: number;
  created_at: string;
  etag: string;
  format: string;
  height: number;
  placeholder: false;
  public_id: string;
  resource_type: 'image' | 'video';
  secure_url: string;
  signature: string;
  tags: [];
  type: string;
  url: string;
  version: number;
  version_id: string;
  width: number;
}

export interface DemographicQuestion {
  id: number;
  options: {
    dataUrl?: null;
    id: number;
    questionId: number;
    title: string;
    titleRight?: null;
  }[];
  questionTypeId: number;
  title: string;
}

export interface DisplayLogicConstraint {
  conceptId?: number | null;
  id: number;
  matrixOptions?: string[] | null;
  numberRange?: { start: number; end: number };
  optionId?: number | null;
}

export type DisplayLogicLogicalModifier = 'is' | 'isnt' | 'should';

export interface DisqualificationQuestion {
  count: number;
  question: {
    id: number;
    isDemographic: boolean;
    isScreener: boolean;
    sort: number;
    title: string;
  };
}

export interface EndMessage {
  subtitle: string;
  title: string;
  type: 'completed' | 'closed' | 'disqualified';
}

export type ExportFilterModifier = 'is' | 'isnt' | 'should';

export type GaborGrangerFormat = 'CUSTOM' | 'DOLLARS' | 'PERCENT';
export type GaborGrangerObjective = 'MAX_SEEKING' | 'MIN_SEEKING';
export interface GaborGrangerSettings {
  format: GaborGrangerFormat;
  formatCustomText: string | null;
  increment: string;
  max: string;
  min: string;
  objective: GaborGrangerObjective;
  unitDecimals: number;
}

export interface JwtPayload {
  baseOrganizationId?: number;
  email: string;
  iat: number;
  id: number;
  organizationId: number;
  role: Role;
}

export interface IncidenceType {
  id: number;
  name:
    | '100-75%'
    | '74-50%'
    | '49-30%'
    | '29-20%'
    | '19-10%'
    | '9-5%'
    | '4-3%'
    | '2-1%';
  lucidEstimate: string;
}

export interface IncidenceTypeWithAverage extends IncidenceType {
  average: number;
}

export interface SurveyStatus {
  name: SurveyStatusName;
  id: number;
}

export interface LucidStatistics {
  client_status: Record<number, number>;
  cost: { amount: number; currency_code: 'USD'; exchange_rate: number };
  earnings_per_click: number;
  fulcrum_status: Record<string, unknown>;
  median_length_of_interview: number;
  median_length_of_term: number;
  system_conversion: number;
  total_entrants: number;
}

export type LOI = '1-20' | '21-40' | '41-60';

export enum OPTION_TYPE {
  IMAGE = 1,
  TEXT = 2,
}

export interface Organization {
  description: null;
  eventObjectId: null;
  id: number;
  isActive: boolean;
  isAdmin: boolean;
  isAssignedOnly: boolean;
  logo: DataUrl;
  name: string;
  plan: string;
  recurringCreditsOverride: string;
  renews: string;
  signUp: string;
  stripeCustomerId: string;
  stripePaymentTimeFrame: number;
  stripePlanId: string;
  useInvoice: boolean;
  userId: number;
}

export interface OrganizationV2 {
  id: number;
  isAdmin: boolean;
  name: string;
  stripeCustomerId: string;
  useInvoice: boolean;
  userId: number;
}

export interface Project {
  audienceDescription: null;
  createdAt: string;
  endAt: string;
  estimatedBalance: string;
  freelancerConnections: [];
  id: number;
  isUniversal: boolean;
  launchAt: string;
  organization: unknown;
  organizationId: number;
  parentId: null;
  projectObjective: null;
  status: unknown;
  statusId: number;
  surveys: [];
  title: string;
  updatedAt: string;
  userId: null;
}

export enum QUESTION_FEATURE {
  CARRY_FORWARD_DISPLAYED = 'COD01',
  CARRY_FORWARD_NOT_SELECTED = 'CON01',
  CARRY_FORWARD_SELECTED = 'COS01',
  MULTIPLE_OPTION_SELECTIONS = 'QMCM01',
  MULTIPLE_RESPONSE_LOWER_LIMIT = 'MCLL01',
  MULTIPLE_RESPONSE_UPPER_LIMIT = 'MCUL01',
  MULTIPLE_RESPONSE = 'MRS01',
  NUMBER_TYPE = 'Number',
  OPEN_MATRIX = 'OM01',
  OPEN_MATRIX_INVERTED = 'OMI01',
  OPEN_MATRIX_SCROLL = 'OMS01',
  PIPE_CONCEPT = 'QPC01',
  QUESTION_SUMMATION = 'QAS01',
  RANDOMIZE_MATRIX_OPTIONS = 'RDM02',
  REVERSE_OPTIONS = 'RMO',
  RANDOMIZE_OPTIONS = 'RDM01',
  REQUIRED_SUM = 'SUM01',
  SELECT_X_OF_Y = 'OPBLCK01',
  SINGLE_RESPONSE = 'SRS01',
  VIEW_ALL_IMAGES = 'VAL01',
  VIEW_CONCEPT = 'VAL02',
  VOXPOPME_FEATURE_1 = 'VoxpopmeFeature01',
  VOXPOPME_FEATURE_2 = 'VoxpopmeFeature02',
}

export enum QUESTION_OPTION_FEATURE {
  ANCHOR = 'QOA01',
  EXCLUSIVE = 'QOE01',
  FREE_TEXT = 'QOFT01',
  PRESERVE = 'PRSVOP01',
  REQUIRE_VIEW = 'VAL03',
}

export enum QUESTION_TYPE {
  GABOR_GRANGER = 14,
  IDEA_PRESENTER = 20,
  MATRIX = 12,
  MULTIPLE_CHOICE = 2,
  NUMBER = 10,
  OPEN_ENDED = 3,
  RANKING = 7,
  SCALE = 6,
  DATE = 9,
}

export interface Question {
  autoSelectOption: boolean;
  backgroundColor: null;
  blockId: null;
  body: null;
  bucketBlockId: null;
  conceptTestMedia?: QuestionConcept[];
  concepts?: QuestionConcept[];
  constraint?: {
    regex?: string;
    errorMessage?: string;
    range?: { start?: number; end?: number };
  };
  contentType?: { description: string; id: number; name: QuestionContentTypes };
  contentTypeId: OPTION_TYPE;
  createdAt: string;
  csvFileName: null;
  csvFileValue: null;
  dependentOnId: null;
  description: string | null;
  label: string | null;
  displayOptionDescription: boolean;
  displayedConcept?: { id: number };
  features?: { code: QUESTION_FEATURE; enumValue?: number }[];
  fulcrumQuestionId: null;
  fulcrumQuestionName: null;
  gaborGrangerSettings?: GaborGrangerSettings;
  hasPriority: boolean;
  id: number;
  isActive: boolean;
  isDemographic: boolean;
  isPublicForAudience: boolean;
  isScreener: boolean;
  isStandard: boolean;
  isTemplate: boolean;
  isUniversal: boolean;
  labels?: QuestionLabel[];
  monadicId?: number;
  monadicBlockSequences?: number;
  options: QuestionOption[];
  questionAudiences?: QuestionAudience[];
  questionComments?: [];
  questionFeatures?: QuestionFeature[];
  questionQuotas?: {
    booleanValue: boolean | null;
    id: number;
    isDone: boolean;
    logicalModifier: QuestionQuotaLogicalModifiers;
    numberCompleted: number;
    numberNeeded: number;
    questionId: number;
    questionOptions: QuestionOption[];
    quotaRelationId: null;
    regex: string | null;
    type: 'enum';
  }[];
  questionType: {
    attributeValue: string;
    description: string;
    id: number;
    name: string;
  };
  questionTypeId: number;
  quotas?: {
    logicalModifier: QuestionQuotaLogicalModifiers;
    optionQuota: number;
    options: number[];
    quotaId: number;
    quotaRelationId: null;
  }[];
  secondaryBlockId: null;
  shouldAskWithoutSurveyAssociation: boolean;
  sort: number;
  surveyId: number;
  title: string;
  type: string;
  updatedAt: string;
  userId: number;
}

export interface QuestionAudience {
  audience: Audience;
  audienceId: number;
  categories: Record<string, unknown>[];
  questionId: number;
  sort: number;
  unionModifier: 'and' | 'or';
}

export interface QuestionAudienceSliceCategoryAttribute {
  audienceAttribute: {
    enumNumberRange?: { end: number; start: number };
    enumRegex: string | null;
    enumValue: QuestionOption | null;
    enumValueId: number;
    // numberRange appears to be present for demographic audiences while enumNumberRange
    // is present for scale / ranking question audiences in display logic.
    numberRange?: { end: number; start: number };
  };
}

export type QuestionBase = 'exposedToOption' | 'exposedToQuestion';

export interface QuestionBlock {
  amountViewed: number | null;
  id: number;
  isRandomized: boolean;
  questionBlocks: ChildBlock[];
  surveyId: number;
  title: string;
}

export interface ChildBlock {
  displayLogic: {
    constraints: DisplayLogicConstraint[];
    logicalModifier: DisplayLogicLogicalModifier;
    questionId: number;
  }[];
  countInX: boolean;
  end: number;
  id: number;
  sort: number;
  start: number;
  title: string;
  parentId?: number;
}

export interface QuestionConcept {
  audience: Audience[];
  audienceId: null;
  completes: number;
  description: string;
  id: number;
  isActive: boolean;
  media: DataUrl;
  preserved: boolean;
  questionId: number;
  target: null;
  targetModifier: null;
}

export type QuestionContentTypes = 'Image' | 'Text';

export interface QuestionFeature {
  booleanValue: null;
  enumRegex: string | null;
  enumValue: number | null;
  feature: {
    code: QUESTION_FEATURE;
    description: string;
    featureCategoryId: number;
    id: number;
    name: string;
  };
  featureId: number;
  numberRange?: { end: number; start: number };
  questionId: number;
  regex: null;
  type: string;
}

export interface QuestionLabel {
  id: number;
  isActive: boolean;
  isAnchored?: boolean;
  isExclusive?: boolean;
  isFreeText: boolean;
  labelFeatures: [];
  optionLabel: string;
  questionsOptionsId: number;
  weight: number | null;
}

export interface QuestionOption {
  anchored?: boolean;
  carryOverParentId: number | null;
  dataUrl: DataUrl | null;
  description: string | null;
  exclusive?: boolean;
  id: number;
  isActive: boolean;
  isFreeTextOption?: boolean;
  labels: QuestionLabel[];
  precodes: null;
  preserved?: boolean;
  questionId: number;
  questionOptionAudiences: QuestionAudience[];
  questionOptionFeatures: {
    feature: {
      code: QUESTION_OPTION_FEATURE;
      description: string;
      featureCategoryId: number;
      id: number;
      name: string;
    };
    featureId: number;
    questionOptionId: number;
  }[];
  questionOptionQuotas: {
    quotaId: number;
    optionId: number;
    waveId: number;
  }[];
  questionQuota: null;
  questionQuotaId: null;
  rangeMax: number | null;
  rangeMin: number | null;
  rangeStep: number | null;
  scaleHighLabel: string | null;
  scaleHighMiddleLabel: null;
  scaleLowLabel: string | null;
  scaleLowMiddleLabel: null;
  scaleMiddleLabel: string | null;
  scaleTypeId: number | null;
  scaleUnitId: number | null;
  sort: number;
  title: string;
  titleRight: null;
  viewRequired?: boolean;
  weight: number | null;
}

export type QuestionOptionWithResults = QuestionOption & {
  count: number;
  rankBreakdown?: {
    base: number;
    count: number;
    percentage: number;
    rank: number;
    title: string;
  }[];
  resultsBreakdown?: {
    base: number;
    concept?: string;
    count: number;
    label?: string;
    percentage: number;
    step?: number;
    title: string;
  }[];
  scaleBreakdown?: {
    base: number;
    count: number;
    percentage: number;
    step: number;
    title: string;
  }[];
  scaleUnit: { id: number; name: string; unitValue: string } | null;
};

export interface QuestionQuotaElement {
  logicalModifier: QuestionQuotaLogicalModifiers;
  numberNeeded: number | '';
  optionIndices: number[];
  type: string;
}

export type QuestionQuotaLogicalModifiers =
  | 'at_most'
  | 'at_least'
  | 'all'
  | 'none';

export interface QuestionTemplate {
  createdAt: string;
  id: number;
  templateDescription: string | null;
  templateTitle: string | null;
  title: string;
  user: {
    id: number;
    firstName: string;
    image: DataUrl | Record<string, unknown> | null;
    lastName: string;
  };
}

export type QuestionWithResults = Omit<
  Question,
  'features' | 'options' | 'quotas'
> & {
  openEndedResponses: {
    id: number;
    value: string;
  }[];
  options: QuestionOptionWithResults[];
  questionQuotas: {
    booleanValue: boolean | null;
    id: number;
    isDone: boolean;
    logicalModifier: QuestionQuotaLogicalModifiers;
    numberCompleted: number;
    numberNeeded: number;
    questionId: number;
    quotaRelationId: number | null;
    regex: string | null;
    type: 'enum';
  }[];
  totalRespondentsCount: number;
};

export type RemoveRespondentsReason = 'nonQuality' | 'quality';

export type Role = 'admin' | 'user';

export interface Survey {
  accountNotNeeded: boolean;
  audience: Audience;
  audienceId: number;
  campaignTypeId: number;
  categoryId: number;
  colorBackground: null;
  colorDescription: null;
  colorTitle: null;
  completionRedirectUrl: null;
  createdAt: string;
  customizations: { endMessages: EndMessage[] };
  description: null;
  endAt: string;
  estimatedBalance: string;
  generateNewExports: boolean;
  handleRedirects: boolean;
  hash: string;
  id: number;
  image: unknown;
  imageBackground: null;
  inFeed: boolean;
  incidenceType: IncidenceType;
  incidenceTypeId: number;
  isBringYourOwnAudience: boolean;
  isNewExport: boolean;
  isPaused: boolean;
  isTemplate: boolean;
  isUniversal: boolean;
  launchAt: string;
  numberOfCompletes: number;
  organizationId: number;
  paidFor: boolean;
  participants: number;
  pauseOff: null;
  pauseOn: null;
  pointsPerAnswer: null;
  project: Omit<Project, 'organization' | 'status'> | null;
  projectId: number | null;
  qualityChecks: SurveyQualityCheck[];
  questions?: Question[];
  requireMainProfile: boolean;
  status: { id: number; name: SurveyStatusName };
  statusId: number;
  subTitle: null;
  title: string;
  useAgeCensus: boolean;
  useEthnicityCensus: boolean;
  useGenderCensus: boolean;
  useImage: boolean;
  useNewApp: boolean;
  useRaceCensus: boolean;
  useRegionCensus: boolean;
  user: unknown;
  userId: number;
  wave: SurveyWave;
  waveId: number;
  whiteLabeled: boolean;
}

export interface SurveyAudienceSlice {
  audienceSliceCategory: {
    audienceSliceCategoryAttributes: {
      audienceAttribute: {
        boolean: boolean | null;
        enumRegex: string | null;
        enumValue: QuestionOption | null;
        enumValueId: number;
        id: number;
        numberRange?: { end: number; start: number };
        question: Question;
        questionId: number;
        r: number | null;
        regex: string | null;
        surveyId: number | null;
        x: number | null;
        y: number | null;
      };
      audienceAttributeId: number;
      audienceSliceCategoryId: number;
      id: number;
    }[];
    audienceSliceId: number;
    id: number;
    logicalModifier: AudienceLogicalModifier;
    percentage: string;
    question: Question;
    questionId: number;
    requiresUpdateRange: boolean;
    surveyId: number | null;
    updateRange: null;
  };
  audienceSliceCategoryId: number;
  audienceSliceId: number;
  id: number;
  isDone: boolean;
  numberCompleted: number;
  numberNeeded: number;
  surveyId: number;
}

export interface SurveyComplete {
  completes: number;
  time: string;
}

export interface SurveyLength {
  id: number;
  name: LOI;
}

export interface SurveyQualityCheck {
  enabled: boolean;
  type:
    | 'bad_respondent'
    | 'copy_paste'
    | 'duplicate'
    | 'presurvey_questionnaire';
}

export type SurveyStatusName = 'approved' | 'completed' | 'draft';

export interface SurveyTemplate {
  createdAt: string;
  description: string;
  id: number;
  questionsAmount: number;
  title: string;
  user: User;
}

export interface SurveyVariable {
  createdAt: string;
  creatorId: number;
  id: number;
  quotas: SurveyVariableQuota[];
  segments: SurveyVariableSegment[];
  sort: number;
  surveyId: number;
  title: string;
  updatedAt: string;
}

export type SurveyVariableModifier = 'is' | 'isnt' | 'should';

export interface SurveyVariableQuota {
  createdAt: string;
  creatorId: number;
  id: number;
  numberNeeded: number | null;
  type: SurveyVariableQuotaType;
  updatedAt: string;
  variableId: number;
}

export interface SurveyVariableQuotaResult {
  count: number;
  id: number;
  numberNeeded: number | null;
  segments: { numberCompleted: number; title: string }[];
  type: SurveyVariableQuotaType;
  variable: { id: number; title: string };
}

export type SurveyVariableQuotaType = 'at_most' | 'all' | 'none';

export interface SurveyVariableSegment {
  createdAt: string;
  id: number;
  isUserSpecified: boolean;
  questions: {
    andGrouping: number;
    constraints: SurveyVariableSegmentConstraint[];
    id: number;
    logicalModifier: 'is' | 'isnt' | 'should';
    questionId: number;
    surveyVariableSegmentId: number;
  }[];
  quotaId: number | null;
  surveyVariableId: number;
  title: string;
  updatedAt: string;
  userIds?: string[];
}

export interface SurveyVariableSegmentConstraint {
  conceptId?: number | null;
  id: number;
  dateRange?: { start: Date | null; end: Date | null };
  matrixOptions?: string[] | null;
  numberRange?: { start: number; end: number };
  optionId?: number | null;
  surveyVariableSegmentQuestionId: number;
}

export interface SurveyVariableSegmentResults {
  count: number;
  id: number;
  isUserSpecified: boolean;
  percent: number;
  title: string;
  total: number;
}

export interface SurveyWithResults extends Survey {
  cohorts: string[];
  percentage: number;
  questions: QuestionWithResults[];
  surveyBanners: Banner[];
  surveyBannerSlices: unknown;
  surveyVariables: SurveyVariable[];
  wave: SurveyWave;
  waves: SurveyWave[];
  waveId: number;
}

export interface QuestionQuotaResult {
  count: number;
  id: number;
  logicalModifier: QuestionQuotaLogicalModifiers;
  numberNeeded: number | null;
  options: { id: number; sort: number; title: string }[];
  question: { id: number; title: string };
}

export interface Tag {
  id: number;
  title: string;
}

export interface TeamMember {
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  image: {
    public_id?: string;
    secure_url?: string;
  };
  lastName: string;
  mobile: string;
  organization: {
    description: null;
    eventObjectId: null;
    id: number;
    isActive: boolean;
    isAssignedOnly: boolean;
    logo: DataUrl | null;
    name: string;
    plan: string | null;
    recurringCreditsOverride: null;
    renews: string | null;
    signUp: string | null;
    stripeCustomerId: string;
    stripePaymentTimeFrame: number;
    stripePlanId: string;
    useInvoice: boolean;
    userId: number;
  };
  organizationId: number;
  role: {
    id: number;
    name: Role;
  };
  roleId: number;
  status: {
    id: number;
    name: string;
  };
  statusId: number;
  updatedAt: string;
  zip: string | null;
}

export interface User {
  email: string;
  firstName: string;
  id: number;
  image: DataUrl | Record<string, unknown>;
  lastName: string;
  mobile: string | null;
  role: { name: Role };
  userHash: string;
  userHashFront: string;
}

export interface UserV2 {
  email: string;
  firstName: string;
  hashFront: string;
  id: number;
  image: DataUrl | Record<string, unknown>;
  lastName: string;
  mobile: string;
}

export interface Quote {
  total: number;
  descrtipion: string;
}
