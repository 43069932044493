import { cloneDeep } from 'lodash-es';
import { useField, FieldHelperProps } from 'formik';

import { getImageUrl } from '../../util/images';
import {
  getOtherQuestionReferencesForOption,
  getVariableReferencesForOption,
  IQuestionReferenceForOption,
  IVariableReference,
} from '../../util/questions';
import { OptionFeatureConfig, OPTION_FEATURES } from '../../util/options';
import {
  Question,
  QUESTION_TYPE,
  SurveyVariable,
} from '../../types/domainModels';
import { QuestionFormData, QuestionFormOption } from '../../types/forms';

import { useModal } from '../../hooks/modals';
import Button from '../common/forms/Button';
import FileUploadWithCrop from '../common/forms/FileUploadWithCrop';
import FormInput from '../common/forms/FormInput';
import OptionFeatureLabel from './OptionFeatureLabel';
import OptionSettings from './OptionSettings';
import PreventDeleteOptionModal from './PreventDeleteOptionModal';
import Tooltip from '../common/Tooltip';
import XButton from '../common/forms/XButton';

const QuestionOption = ({
  hasLabel = true,
  index,
  isEditable = true,
  isImage,
  labelPrefix = 'Option',
  namePrefix = 'options',
  onClickRemove,
  question,
  questions,
  variables,
}: {
  hasLabel?: boolean;
  index: number;
  isEditable?: boolean;
  isImage: boolean;
  labelPrefix?: string;
  namePrefix?: 'labels' | 'options';
  onClickRemove?: () => void;
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}): JSX.Element => {
  const fieldPrefix = `${namePrefix}.${index}`;
  const [{ value: option }] = useField<QuestionFormOption>(`${fieldPrefix}`);

  const [{ value: optionType }] =
    useField<QuestionFormData['optionType']>('optionType');
  const [{ value: questionFeatures }] =
    useField<QuestionFormData['features']>('features');
  const [{ value: questionType }] =
    useField<QuestionFormData['questionType']>('questionType');

  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${fieldPrefix}.weight`,
  );
  const [, , activeHelper] = useField(
    `${namePrefix}.${index}.features.isActive`,
  );

  const availableOptionFeatures = OPTION_FEATURES.filter(({ available }) => {
    return available({
      features: questionFeatures,
      isLabel: namePrefix === 'labels',
      isMatrixOption: false,
      optionType: optionType?.value,
      questionType,
    });
  });

  return (
    <div className="flex p-2 bg-white flex-1">
      {hasLabel && (
        <div className="flex-shrink-0 w-14 mr-2 text-xs select-none flex items-center">
          {labelPrefix} {index + 1}
        </div>
      )}
      <div className="flex w-full space-x-4 items-center">
        {isImage && (
          <OptionImage
            disabled={!isEditable || !!option.carryOverParentId}
            fieldPrefix={fieldPrefix}
          />
        )}

        <div className="flex-grow">
          <FormInput
            disabled={!isEditable || !!option.carryOverParentId}
            name={`${fieldPrefix}.value`}
            size="md"
            type="text"
          />
          <OptionFeatureLabels
            availableOptionFeatures={availableOptionFeatures}
            carryOverParentId={option.carryOverParentId}
            fieldPrefix={fieldPrefix}
          />
        </div>

        {questionType?.value === QUESTION_TYPE.MULTIPLE_CHOICE &&
          option.features.useWeight && (
            <div className="w-12">
              <FormInput
                disabled={!isEditable}
                name={`${fieldPrefix}.weight`}
                placeholder="Weight (#)"
                size="md"
                type="number"
              />
            </div>
          )}
        {isEditable && (
          <div className="flex items-center space-x-2">
            <OptionActions
              activeHelper={activeHelper}
              availableOptionFeatures={availableOptionFeatures}
              index={index}
              namePrefix={namePrefix}
              onClickRemove={onClickRemove}
              optionId={option.id}
              question={question}
              questionType={questionType}
              questions={questions}
              variables={variables}
              weightHelper={weightHelper}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionOption;

const OptionImage = ({
  disabled,
  fieldPrefix,
}: {
  disabled: boolean;
  fieldPrefix: string;
}): JSX.Element => {
  const [{ value: optionImage }, , optionImageHelpers] = useField<
    QuestionFormOption['image']
  >(`${fieldPrefix}.image`);

  const imageUrl =
    typeof optionImage === 'string' ? optionImage : getImageUrl(optionImage);

  return (
    <>
      <FileUploadWithCrop
        disabled={disabled}
        onDone={(image) => {
          optionImageHelpers.setValue(image);
        }}
      >
        <Button disabled={disabled} hierarchy="secondary-gray" size="xs">
          Upload
        </Button>
      </FileUploadWithCrop>
      {imageUrl && (
        <div>
          <Tooltip
            showDelay={350}
            trigger={
              <div className="flex items-center justify-center w-8 h-8">
                <img className="max-w-full max-h-full" src={imageUrl} />
              </div>
            }
          >
            <div className="flex items-center justify-center w-64 h-64">
              <img className="max-w-full max-h-full" src={imageUrl} />
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

const OptionFeatureLabels = ({
  availableOptionFeatures,
  carryOverParentId,
  fieldPrefix,
}: {
  availableOptionFeatures: OptionFeatureConfig[];
  carryOverParentId: number | null;
  fieldPrefix: string;
}): JSX.Element => {
  const [{ value: optionFeatures }, , optionFeatureHelpers] = useField<
    QuestionFormOption['features']
  >(`${fieldPrefix}.features`);

  // const [{ value: preserved }, , preservationHelpers] = useField<
  //   QuestionFormOption['preserved']
  // >(`${fieldPrefix}.preserved`);
  const [, , weightHelper] = useField<QuestionFormOption['weight']>(
    `${fieldPrefix}.weight`,
  );

  return (
    <div>
      <div className="space-x-2">
        {carryOverParentId && <OptionFeatureLabel label="Carry Forward" />}
        {optionFeatures.displayLogic && optionFeatures.displayLogic.enabled && (
          <OptionFeatureLabel
            label="Display Logic"
            onClickRemove={() => {
              const newOptionFeatures = cloneDeep(optionFeatures);
              newOptionFeatures.displayLogic.enabled = false;

              optionFeatureHelpers.setValue(newOptionFeatures);
            }}
          />
        )}
        {/* {preserved && (
          <OptionFeatureLabel
            label="Preserved"
            onClickRemove={() => {
              preservationHelpers.setValue(false);
            }}
          />
        )} */}
        {optionFeatures.useWeight && (
          <OptionFeatureLabel
            label="weight"
            onClickRemove={() => {
              const newOptionFeatures = cloneDeep(optionFeatures);
              newOptionFeatures.useWeight = false;
              weightHelper.setValue(null);

              optionFeatureHelpers.setValue(newOptionFeatures);
            }}
          />
        )}
        {availableOptionFeatures.map(
          ({ featureName, inverseDisplay = false, label, labelDisplay }) => {
            const isEnabled = optionFeatures?.[featureName];
            const showLabel = inverseDisplay ? !isEnabled : isEnabled;

            if (showLabel) {
              return (
                <OptionFeatureLabel
                  key={featureName}
                  label={labelDisplay || label}
                  onClickRemove={() => {
                    optionFeatureHelpers.setValue({
                      ...optionFeatures,
                      [featureName]: inverseDisplay,
                    });
                  }}
                />
              );
            }

            return null;
          },
        )}
      </div>
    </div>
  );
};

const OptionActions = ({
  activeHelper,
  availableOptionFeatures,
  index,
  namePrefix,
  onClickRemove,
  optionId,
  question,
  questions,
  questionType,
  weightHelper,
  variables,
}: {
  activeHelper: FieldHelperProps<boolean>;
  availableOptionFeatures: OptionFeatureConfig[];
  index: number;
  namePrefix: 'concepts' | 'labels' | 'options';
  onClickRemove?: () => void;
  optionId: number | null;
  question: Question | undefined;
  questions: Question[];
  questionType: QuestionFormData['questionType'];
  weightHelper: FieldHelperProps<number | null>;
  variables: SurveyVariable[];
}): JSX.Element => {
  const {
    isOpen: isPreventDeleteModalOpen,
    onCloseModal,
    setIsOpen: setIsPreventDeleteModalOpen,
  } = useModal();

  return (
    <>
      <OptionSettings
        activeHelper={activeHelper}
        availableOptionFeatures={availableOptionFeatures}
        index={index}
        isWithinMonadicLoop={!!question?.monadicId}
        namePrefix={namePrefix}
        optionId={optionId}
        question={question}
        questionType={questionType}
        questions={questions}
        setIsPreventDeleteModalOpen={setIsPreventDeleteModalOpen}
        variables={variables}
        weightHelper={weightHelper}
      />
      {onClickRemove && (
        <XButton
          onClick={() => {
            if (optionId) {
              let variableReferences: IVariableReference[] = [];
              let questionReferences: IQuestionReferenceForOption[] = [];
              if (questions) {
                questionReferences = getOtherQuestionReferencesForOption({
                  optionId,
                  questions,
                });
              }
              if (variables) {
                variableReferences = getVariableReferencesForOption({
                  optionId,
                  variables,
                });
              }
              const hasOtherReferences =
                questionReferences.length > 0 || variableReferences.length > 0;

              if (hasOtherReferences) {
                setIsPreventDeleteModalOpen(true);

                return;
              }
            }

            onClickRemove();
          }}
          title="Remove"
        />
      )}

      {isPreventDeleteModalOpen && optionId && (
        <PreventDeleteOptionModal
          onCloseModal={onCloseModal}
          optionId={optionId}
          questions={questions}
          type={namePrefix}
          variables={variables}
        />
      )}
    </>
  );
};
