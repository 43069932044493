import { toast } from 'react-toastify';

export function showErrorMessage(message: string) {
  if (message.indexOf('duplicate key') === -1) {
    toast.error(message);
  }
}

export function showSuccessMessage(message: string) {
  toast.success(message);
}
