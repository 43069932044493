import { getEnvVar } from 'util/env';

export type APIVersion = 'v1' | 'v2';

export function getAPIUrl({
  path,
  usePreCloudRunInfra = false,
  version,
}: {
  path: string;
  usePreCloudRunInfra?: boolean;
  version: APIVersion;
}) {
  const host = usePreCloudRunInfra
    ? getEnvVar('API_BASE_URL_OLD')
    : getEnvVar('API_BASE_URL');

  return `${host}/api/${version === 'v1' ? `${version}/admin` : version}${path}`;
}
