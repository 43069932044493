import FormCheckbox from '../../common/forms/FormCheckbox';

const RandomizeLabelOrder = (): JSX.Element => {
  return (
    <>
      <FormCheckbox
        checkboxLabel="Randomize Label Order"
        name="features.randomizeLabelOrder"
      />
    </>
  );
};

export default RandomizeLabelOrder;
