import { Form, Formik } from 'formik';
import { partition } from 'lodash-es';
import { useQueryClient } from '@tanstack/react-query';

import { generateQuestionsSection } from 'util/questions';
import {
  getInitialSurveyVariableFormData,
  SurveyVariableFormData,
  SurveyVariableFormDataValidated,
  validateSurveyVariableData,
} from 'util/surveyVariables';
import { getNestedErrorMessages } from 'util/forms';
import { getQuestionOption } from 'util/formOptions';
import { Question, QUESTION_TYPE, Survey } from '../../types/domainModels';
import { showErrorMessage, showSuccessMessage } from 'util/notifications';
import { useSaveSurveyVariables } from 'hooks/backend/surveyVariables';
import { useSubmitValidation } from 'hooks/forms';

import { AddUserIds, Quotas, Segments } from './SurveyVariables';
import Button from 'components/common/forms/Button';
import ButtonLoading from 'components/common/forms/ButtonLoading';
import FormErrorsAlert from 'components/common/forms/FormErrorsAlert';
import FormInput from 'components/common/forms/FormInput';
import IndexCard from 'components/common/IndexCard';
import Modal, { ModalHeader } from 'components/common/Modal';

const VariableEditModal = ({
  onCloseModal,
  survey,
}: {
  onCloseModal(): void;
  survey: Survey;
}): JSX.Element => {
  const queryClient = useQueryClient();

  const { isPending: isSavingVariable, mutateAsync: saveVariable } =
    useSaveSurveyVariables({
      onError: (err: Error) => {
        showErrorMessage(
          `There was an error saving the variable. Error: ${err.message}`,
        );
      },
      onSuccess: () => {
        showSuccessMessage('The variable was saved successfully.');
        queryClient.invalidateQueries();
        onCloseModal();
      },
      survey,
      variable: undefined,
    });

  const [demographicQuestions, questions] = partition(
    survey.questions,
    'isDemographic',
  );
  const initialValues = getInitialSurveyVariableFormData({
    questions: [
      ...demographicQuestions,
      ...questions,
      {
        title: 'Use Completion Date',
        id: -1,
        questionTypeId: QUESTION_TYPE.DATE,
      } as Question,
    ],
    variable: undefined,
  });

  return (
    <Modal
      header={
        <ModalHeader onClickClose={onCloseModal}>New Variable</ModalHeader>
      }
      onCloseModal={onCloseModal}
      position="top"
      size="auto"
    >
      <div className="w-192">
        <Formik<SurveyVariableFormData>
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(formData) => {
            return saveVariable(formData as SurveyVariableFormDataValidated);
          }}
          validate={(formData) => {
            return validateSurveyVariableData(formData);
          }}
          validateOnBlur={false}
          validateOnChange={false}
        >
          <Form>
            <SurveyVariableForm
              isSavingVariable={isSavingVariable}
              onClickCancel={onCloseModal}
              questions={questions}
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

export default VariableEditModal;

const SurveyVariableForm = ({
  isSavingVariable,
  onClickCancel,
  questions,
}: {
  isSavingVariable: boolean;
  onClickCancel(): void;
  questions: Question[];
}): JSX.Element => {
  const questionGroups = [
    generateQuestionsSection({
      questions: questions
        .filter((question) => question.isDemographic)
        .map((question) => getQuestionOption({ question })),
      title: 'Demographic Questions',
    }),
    generateQuestionsSection({
      questions: questions
        .filter(
          (question) =>
            ![QUESTION_TYPE.OPEN_ENDED, QUESTION_TYPE.GABOR_GRANGER].includes(
              question.questionTypeId,
            ) && !question.isDemographic,
        )
        .map((question) => {
          return getQuestionOption({ question });
        }),
      title: 'Survey Questions',
    }),
    {
      label: 'DATE RANGE',
      options: [
        {
          label: 'Use Completion Date',
          value: { id: -1, questionTypeId: QUESTION_TYPE.DATE } as Question,
        },
      ],
    },
  ];

  const { errors, onClickSubmit } = useSubmitValidation({
    isSaving: isSavingVariable,
  });

  return (
    <div>
      {errors ? (
        <div className="mb-8">
          <FormErrorsAlert errors={getNestedErrorMessages(errors)} />
        </div>
      ) : null}

      <IndexCard>
        <div className="space-y-4">
          <div className="flex justify-between px-6 p-6">
            <div className="w-1/2">
              <FormInput label="Title" name="title" size="md" />
            </div>
          </div>

          <Segments questions={questionGroups} />
          <hr className="text-light-grey" />
          <Quotas />
          <AddUserIds />
        </div>
      </IndexCard>

      <div className="mt-8 flex gap-3 flex-row-reverse">
        <ButtonLoading
          grow
          hierarchy="primary"
          isLoading={isSavingVariable}
          onClick={onClickSubmit}
          size="lg"
          // This can't currently be a submit button since we handle the form submission
          // in the onClickSubmit callback. If this is a "submit" button, it causes a double submission.
          type="button"
        >
          Save Variable
        </ButtonLoading>
        <Button
          grow
          hierarchy="secondary-gray"
          onClick={onClickCancel}
          size="lg"
          type="button"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};
