import { lazy, Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import 'cropperjs/dist/cropper.css';

import { ChatSupportProvider } from 'contexts/chatSupport';
import { RouteBlockerProvider } from 'contexts/routeBlocker';

import ErrorBoundary from './errors/ErrorBoundary';
import Icon from './common/Icon';

const ReactQueryDevtoolsProduction = lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then(
    (d) => ({
      default: d.ReactQueryDevtools,
    }),
  ),
);

declare global {
  interface Window {
    FS: {
      identify(userId: string, opts: Record<string, unknown>): void;
    };
  }
}

const App = (): JSX.Element => {
  const location = useLocation();

  // React Router doesn't implement scroll restoration. So this is a naive approach until browsers handle it.
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [showDevtools, setShowDevtools] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ErrorBoundary>
      <ChatSupportProvider>
        <RouteBlockerProvider>
          <div className="h-full">
            <ToastContainer
              autoClose={5000}
              bodyClassName="items-start text-sm"
              closeButton={({ closeToast }) => {
                return (
                  <button
                    className="w-4 h-4 shrink-0"
                    onClick={closeToast}
                    type="button"
                  >
                    <Icon id="x" />
                  </button>
                );
              }}
              draggable
              draggablePercent={60}
              hideProgressBar
              icon={({ type }) => {
                return type === 'success' ? (
                  <div className="w-4 h-4 mt-0.5 text-primary-d-600">
                    <Icon id="check" />
                  </div>
                ) : type === 'error' ? (
                  <div className="w-4 h-4 mt-0.5 text-red">
                    <Icon id="alert-circle" />
                  </div>
                ) : null;
              }}
              position="bottom-right"
              stacked
              transition={Slide}
            />
            <Outlet />
          </div>
        </RouteBlockerProvider>
      </ChatSupportProvider>

      {showDevtools && (
        <Suspense fallback={null}>
          <ReactQueryDevtoolsProduction />
        </Suspense>
      )}
    </ErrorBoundary>
  );
};

export default App;
