import { isProduction } from 'util/environment';
import { Question, SurveyVariable } from '../../../types/domainModels';
import { QuestionFormOption } from '../../../types/forms';

import { useAuth } from 'contexts/auth';
import { useHasRole } from '../../../hooks/users';
import ConceptField from '../ConceptField';
import DisplayLogic from '../questionFeatures/DisplayLogic';
import QuestionOption from '../QuestionOption';
import SurveyEditRow from '../SurveyEditRow';
import SurveyEditRowLeftSide from '../SurveyEditRowLeftSide';
import ViewConcept from '../questionFeatures/ViewConcept';
import UseMonadicBlock from '../questionFeatures/UseMonadicBlock';

const IdeaPresenterQuestion = ({
  concepts = [],
  question,
  questions,
  variables,
}: {
  concepts?: QuestionFormOption[];
  question: Question | undefined;
  questions: Question[];
  variables: SurveyVariable[];
}): JSX.Element => {
  const { user } = useAuth();
  const isAdmin = useHasRole('admin');

  // We allow user with a specific ID to access this feature due to a DIY client request.
  // See https://linear.app/glass-tech/issue/GLA-695/allow-user-to-access-monadic-block-option-when-building-a-survey.
  const canAccessMonadicBlockFeature =
    isAdmin || (isProduction() && user?.id === 2982334);

  return (
    <>
      <SurveyEditRow
        layout={concepts.length > 0 ? 'column' : 'row'}
        subtitle="(optional)"
        title="Concept Image or Video"
        tooltip="Upload either image or video files. Suggested formats are jpeg/png for images or mp4 for video."
      >
        <ConceptField
          concepts={concepts}
          question={question}
          questions={questions}
          variables={variables}
        />
      </SurveyEditRow>

      <div className="p-6 border-b border-gray-300">
        <SurveyEditRowLeftSide title="Option" />
        <p className="pb-2 text-sm">Idea presenters have one default option.</p>
        <QuestionOption
          index={0}
          isEditable={false}
          isImage={false}
          question={question}
          questions={questions}
          variables={variables}
        />
      </div>
      <div className="p-6">
        <SurveyEditRowLeftSide title="Question Features" />
        <div className="mx-4 mt-4 space-y-4">
          <ViewConcept />
          <DisplayLogic question={question} questions={questions} />
          {canAccessMonadicBlockFeature && (
            <UseMonadicBlock
              question={question}
              questionsLength={questions.filter((q) => !q.isDemographic).length}
              sequencesUpperBound={concepts.length}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default IdeaPresenterQuestion;
